<template>
  <div class="Home__warpper">
    <Slider/>
    <!-- <LearnAbout/>
    <Service />
    <WhyRegister/>
    <Teacher/>
    <JoinSession/>
    <Blog/> -->
    <Subscriber/>
  </div>
</template>
<script>
import Slider from '../../components/Slider/Slider'
// import LearnAbout from '../../components/LearnAbout/LearnAbout'
// import Service from '../../components/Service/Service'
// import WhyRegister from '../../components/WhyRegister/WhyRegister'
// import Teacher from '../../components/Teacher/Teacher'
// import JoinSession from '../../components/JoinSession/JoinSession'
// import Blog from '../../components/Blog/Blog'
import Subscriber from '../../components/Subscriber/Subscriber'
export default {
  name: 'HomePage',
  components: {
    Slider,
    // LearnAbout,
    // Service,
    // WhyRegister,
    // Teacher,
    // JoinSession,
    // Blog,
    Subscriber,
  },
  props: {
    msg: String
  }
}
</script>