import Vue from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free/css/all.css'
import * as VueGoogleMaps from 'vue2-google-maps'
// import 'vuejs-google-maps/dist/vuejs-google-maps.css'
import './assets/style.css'
import './assets/responsive.css'
Vue.config.productionTip = false

// map init
Vue.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyBVZsxaCMR-9GIhNJ02Zm-xuap977cvRe0',
    libraries: 'places',
  }
})
// router initialization
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { routes } from "./router/index"
const router = new VueRouter({
  routes,
  mode: 'history',
  // base:'/kintergartenvue/'
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
