<template>
   <div class="contact__container padding__top__botton"> 
      <div class="container">
          <div class="row">
              <div class="col-lg-10 offset-lg-2 mx-auto">
                  <div class="row">
                      <div class="col-lg-6">
                          <div class="contact__left">
                              <div class="section__title__container mb-3">
                                  <h5 class="section__subtitle">Thank you, you are now subscribed {{ $route.query.email }}</h5>
                              </div>
                             
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> 
</template>
<script>
export default {
  name: 'Contact',
  components: {  },
  data(){
      return{
          
      }
  },
  props: {
    msg: String
  },
  methods: {}
     
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

