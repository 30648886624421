<template>
  <div>
    <breadcrumb title="contact" title1="" />
    <div class="contact__wrapper">  
      <TimeTable/>
    </div>
  </div>
</template>
<script>
import TimeTable from "../../components/TimeTable/TimeTable.vue";

export default {
  name: "TimeTablePage",
  components: {
    TimeTable,
  },
  data() {
    return {};
  },
};
</script>
