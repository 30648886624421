<template>
  <div>
    <div class="navbar__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 col-xl-6">
            <ul class="nav__top__left">
              <li><i class="fas fa-envelope"></i> info@gurkul.co.uk</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-2 col-xl-6">
            <ul class="top__social">
              <!-- <li><a href="www.factbook.com"><i class="fab fa-facebook-f"></i></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar__wrapper" :class="{ mystky: active }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="navbar__inner_container">
              <div class="logo">
                <h4>
                  <router-link to="/">
                    <img :src="logo" alt="logo" class="" />
                  </router-link>
                </h4>
              </div>
              <div class="manu">
                <ul :class="vissible ? 'vissible' : 'hidden'">
                  <li @click="scrollTop">
                    <router-link to="/"
                      >Maths Operator Practice Sheet
                    </router-link>
                  </li>
                  <li @click="scrollTop">
                    <router-link to="/events">Maths Number Bonds</router-link>
                  </li>
                  <li @click="scrollTop">
                    <router-link to="/timetable">Times Table</router-link>
                  </li>
            
                  <!-- <li @click="scrollTop"><router-link to="/contact">Contact</router-link></li>  -->
                  <ul class="mega" :class="mega ? 'mega__visible' : ''">
                    <li @click="scrollTop">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li @click="scrollTop">
                      <router-link to="/events">Events</router-link>
                    </li>
                    <li @click="scrollTop">
                      <router-link to="/timetable">Time Table</router-link>
                    </li>

                    <!-- <li @click="scrollTop"><router-link to="/contact">Contact</router-link></li> -->
                    <li @click="scrollTop">
                      <router-link to="/error">Error</router-link>
                    </li>
                  </ul>
                </ul>
                <div
                  :class="vissible ? 'vissible__cross' : 'hidden__corss'"
                  @click="handleClickCross"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
              <div class="mobile__menu" @click="handleClick">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/images/logo.png";
export default {
  name: "Navbar",
  data() {
    return {
      logo: logo,
      vissible: false,
      active: false,
      mega: false,
    };
  },
  methods: {
    scrollHandleScroll() {
      if (window.scrollY > 70) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    handleClick() {
      this.vissible = true;
    },
    handleClickCross() {
      this.vissible = false;
    },
    handleMega() {
      this.mega = !this.mega;
    },
    scrollTop() {
      window.scrollTo(0, 0);
      this.vissible = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandleScroll);
    console.log(this.$router.history.base);
  },
};
</script>