<template>
  <div class="slider__wrapper video__fixed">
    <div class="container">
      <div class="row row__reverce">
        <div class="col-lg-6">
          <carousel
            :per-page="1"
            :paginationEnabled="false"
            :navigationEnabled="true"
            :navigationPrevLabel="`<i class=&quot;fas fa-arrow-left&quot; ></i>`"
            :navigationNextLabel="`<i class=&quot;fas fa-arrow-right&quot; ></i>`"
          >
            <slide v-for="(slider, index) in sliders" :key="index">
              <div class="slider__inner">
                <div class="single__slider">
                  <!-- <div class="video__modal">
                                <button @click="play(slider.videoUrl)" class="btn video__btn" v-if="slider.videoUrl">
                                    <i  class="fas fa-play" ></i>
                                </button>
                            </div> -->
                  <h1>
                    <span class="above__title">{{ slider.title1 }} </span>
                    {{ slider.title2 }}
                  </h1>
                  <table style="width: 30%">
                    <tr>
                      <td>
                        <label for="Multiplication"> Multiplication</label>
                      </td>

                      <td>
                        <input
                          type="radio"
                          id="Multiplication"
                          value="1"
                          v-model="option"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td><label for="Addition">Addition</label></td>
                      <td>
                        <input
                          type="radio"
                          id="Addition"
                          value="2"
                          v-model="option"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label for="PositiveSubtraction">
                          PositiveSubtraction</label
                        >
                      </td>
                      <td>
                        <input
                          type="radio"
                          id="PositiveSubtraction"
                          value="3"
                          v-model="option"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label for="PerfectDivision">PerfectDivision</label>
                      </td>
                      <td>
                        <input
                          type="radio"
                          id="PerfectDivision"
                          value="4"
                          v-model="option"
                        />
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <span>Enter Minimum Number</span>
                      </td>
                      <td>
                        <select v-model="minimum">
                          <option disabled value="">Please select one</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Enter Maximum Number</span>
                      </td>
                      <td>
                        <select v-model="maximum">
                          <option disabled value="">Please select one</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                        </select>
                      </td>
                    </tr>
                    <!-- <tr>
                            <td>
                              <button v-on:click="getPDF()">Get PDF</button>
                            </td>
                          </tr> -->
                  </table>

                  <p class="slider__para">{{ slider.content }}</p>
                  <div class="slider__btns">
                    <button v-on:click="getPDF()" class="cmn__btn">
                      Get PDF<i class="fas fa-arrow-right"></i>
                    </button>
                    <!-- <router-link to="/fdfs" class="learn__btn cmn__btn">Learn More<i class="fas fa-arrow-right" ></i></router-link> -->
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-lg-6">
          <div class="slider__image">
            <img :src="slider" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="slider__side__color">
      <img :src="kid2" alt="kid2" />
    </div>
    <div class="slider__side__color1">
      <img :src="kid1" alt="kid1" />
    </div>
    <div class="videooo" v-if="playCondition" @click="close">
      <div class="video-body">
        <div class="close-wrapper">
          <span class="close-video" @click="close"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <iframe :src="videoUrl"> </iframe>
        hi
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// import carousel from 'vue-owl-carousel'
import { Carousel, Slide } from "vue-carousel";
import slider from "../../assets/images/slider.svg";
import kid1 from "../../assets/images/kid1.svg";
import kid2 from "../../assets/images/kid2.svg";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 10 });
export default {
  name: "Slider",
  components: { Carousel, Slide },
  data() {
    return {
      slider: slider,
      option: 1,
      minimum: 2,
      maximum: 10,
      kid1: kid1,
      kid2: kid2,
      playCondition: false,
      videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
      sliders: [
        {
          videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
          title1: "Mental Maths",
          content: "Improve Child Maths aptitute with our free PDF",
        },
      ],
    };
  },
  props: {
    msg: String,
  },
  methods: {
    play(url) {
      this.playCondition = true;
      this.videoUrl = url;
    },
    close() {
      this.playCondition = false;
    },
    downloadPDF(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "vct_illustration.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getPDF() {
      // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      // axios.defaults.headers.common["Authorization"] =
      //   "c0cd985e-1a9d-4cc5-b189-99b68e76d117";
      axios({
        url:
         "https://gu4uyg0yee.execute-api.eu-west-2.amazonaws.com/dev/sheets/maths/option/" +
          // "http://localhost:8445/sheets/maths/option/" +
          this.option +
          "/min/" +
          this.minimum +
          "/max/" +
          this.maximum,
        method: "GET",
        responseType: "string",
      }).then((response) => {
        console.warn("Res6", response.data);
        var pdf = response.data;
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");

        var fileName = "Maths_";

        switch (parseInt(this.option)) {
          case 1:
            fileName += "Multiplication";
            break;
          case 2:
            fileName += "Addition";
            break;
          case 3:
            fileName += "PositiveSubtraction";
            break;
          case 4:
            fileName += "PerfectDivision";
            break;
          default:
            fileName += "DEF";
            console.log("Try looking up for a hint.");
        }

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

      console.warn(
        "called with http 7",
        this.option,
        this.minimum,
        this.maximum
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

