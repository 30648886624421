<template>
  <div class="slider__wrapper video__fixed">
    <div class="container">
      <div class="row row__reverce">
        <div class="col-lg-6">
          <slide v-for="(slider, index) in sliders" :key="index">
            <div class="slider__inner">
              <div class="single__slider">
                <h1>
                  <span class="above__title">{{ slider.title1 }} </span>
                  {{ slider.title2 }}
                </h1>
                <table class="table table-striped">
                  <tr>
                    <td style="text-align: right">
                      <label for="Addition">Addition</label>
                    </td>
                    <td style="text-align: left">
                      <input
                        type="radio"
                        id="Addition"
                        v-model="option"
                        v-bind:value="2"
                      />
                    </td>

                    <td style="text-align: right">
                      <label for="Subtraction"> Subtraction</label>
                    </td>
                    <td style="text-align: left">
                      <input
                        type="radio"
                        id="Subtraction"
                        v-bind:value="3"
                        v-model="option"
                      />
                    </td>
                    <td style="text-align: right">
                      <label for="Subtraction"> Multiplication</label>
                    </td>
                    <td style="text-align: left">
                      <input
                        type="radio"
                        id="Multiplication"
                        v-bind:value="1"
                        v-model="option"
                      />
                    </td>
                    <td style="text-align: right">
                      <label for="Subtraction"> Division</label>
                    </td>
                    <td style="text-align: left">
                      <input
                        type="radio"
                        id="Division"
                        v-bind:value="4"
                        v-model="option"
                      />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td>
                      <span>Number Bond upto</span>
                    </td>
                    <td>
                      <span>&nbsp;</span>
                    </td>
                    <td>
                      <select v-model="minimum">
                        <option disabled value="">Please select one</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                        <option>32</option>
                        <option>33</option>
                        <option>34</option>
                        <option>35</option>
                        <option>36</option>
                        <option>37</option>
                        <option>38</option>
                        <option>39</option>
                        <option>40</option>
                        <option>41</option>
                        <option>42</option>
                        <option>43</option>
                        <option>44</option>
                        <option>45</option>
                        <option>46</option>
                        <option>47</option>
                        <option>48</option>
                        <option>49</option>
                        <option>50</option>
                        <option>51</option>
                        <option>52</option>
                        <option>53</option>
                        <option>54</option>
                        <option>55</option>
                        <option>56</option>
                        <option>57</option>
                        <option>58</option>
                        <option>59</option>
                        <option>60</option>
                        <option>61</option>
                        <option>62</option>
                        <option>63</option>
                        <option>64</option>
                        <option>65</option>
                        <option>66</option>
                        <option>67</option>
                        <option>68</option>
                        <option>69</option>
                        <option>70</option>
                        <option>71</option>
                        <option>72</option>
                        <option>73</option>
                        <option>74</option>
                        <option>75</option>
                        <option>76</option>
                        <option>77</option>
                        <option>78</option>
                        <option>79</option>
                        <option>80</option>
                        <option>81</option>
                        <option>82</option>
                        <option>83</option>
                        <option>84</option>
                        <option>85</option>
                        <option>86</option>
                        <option>87</option>
                        <option>88</option>
                        <option>89</option>
                        <option>90</option>
                        <option>91</option>
                        <option>92</option>
                        <option>93</option>
                        <option>94</option>
                        <option>95</option>
                        <option>96</option>
                        <option>97</option>
                        <option>98</option>
                        <option>99</option>
                        <option>100</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Enter Number of Question</span>
                    </td>
                    <td>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </td>
                    <td>
                      <select v-model="maximum">
                        <option disabled value="">Please select one</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>

                        <option>32</option>
                        <option>33</option>
                        <option>34</option>
                        <option>35</option>
                        <option>36</option>
                        <option>37</option>
                        <option>38</option>
                        <option>39</option>
                        <option>40</option>
                      </select>
                    </td>
                  </tr>
                  <!-- <tr>
                            <td>
                              <button v-on:click="getPDF()">Get PDF</button>
                            </td>
                          </tr> -->
                </table>

                <p class="slider__para">{{ slider.content }}</p>
                <div class="section__title__container mb-3">
                  <div :style="stylee" class="table-light" v-html="res"></div>
                </div>
                <div class="slider__btns">
                  <button v-on:click="getPDF2()" class="cmn__btn">
                    {{ generate }}<i class="fas fa-arrow-right"></i>
                  </button>
                  <button v-on:click="download()" class="cmn__btn">
                    Download PDF<i class="fas fa-arrow-right"></i>
                  </button>
                  <button v-on:click="reset()" class="cmn__btn">
                    Reset<i class="fas fa-arrow-right"></i>
                  </button>
                  <!-- <router-link to="/fdfs" class="learn__btn cmn__btn">Learn More<i class="fas fa-arrow-right" ></i></router-link> -->
                </div>
              </div>
            </div>
          </slide>
        </div>
        <div class="col-lg-6">
          <div class="slider__image">
            <img :src="slider" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="slider__side__color">
      <img :src="kid2" alt="kid2" />
    </div>
    <div class="slider__side__color1">
      <img :src="kid1" alt="kid1" />
    </div>
    <div class="videooo" v-if="playCondition" @click="close">
      <div class="video-body">
        <div class="close-wrapper">
          <span class="close-video" @click="close"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <iframe :src="videoUrl"> </iframe>
        hi
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import slider from "../../assets/images/slider.svg";
import kid1 from "../../assets/images/kid1.svg";
import kid2 from "../../assets/images/kid2.svg";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 10 });
export default {
  name: "Slider",
  components: {},
  data() {
    return {
      slider: slider,
      option: 2,
      generate: "Generate",
      res: "",
      stylee: "",
      minimum: 20,
      maximum: 10,
      kid1: kid1,
      kid2: kid2,
      playCondition: false,
      videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
      sliders: [
        {
          videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
          title1: "Number Bonds",
          content: "Number Bond Practice Sheets",
        },
      ],
    };
  },
  props: {
    msg: String,
  },
  methods: {
    download() {
      var fileName = "Maths_";

      switch (parseInt(this.option)) {
        case 1:
          fileName += "Multiplication";
          break;
        case 2:
          fileName += "Addition";
          break;
        case 3:
          fileName += "PositiveSubtraction";
          break;
        case 4:
          fileName += "PerfectDivision";
          break;
        default:
          fileName += "DEF";
          console.log("Try looking up for a hint.");
      }

      var pdf = new jsPDF("p", "pt", "a4");
      pdf.internal.pageSize.width = 1000;
      pdf.html(document.getElementById("doc"), {
        callback: function (pdf) {
          pdf.save(fileName + ".pdf");
        },
      });
    },
    play(url) {
      this.playCondition = true;
      this.videoUrl = url;
    },
    reset() {
      this.option = 2;
      this.generate = "Generate";
      this.res = "";
      this.stylee = "";
      console.warn("data reset");
      this.minimum = 20;
      this.maximum = 10;
    },

    getPDF2() {
      axios({
        url:
          "https://gu4uyg0yee.execute-api.eu-west-2.amazonaws.com/dev/sheets/maths/number-bonds/option/" +
           // "http://localhost:8445/sheets/maths/number-bonds/option/" +
          this.option +
          "/for/" +
          this.minimum +
          "/max/" +
          this.maximum +
          "/html",
        method: "GET",
        responseType: "string",
      })
        .then((response) => {
          this.stylee =
            "color: black;border: 4px solid blue;border-radius: 4px;";
          this.generate = "Generate Again";
          console.warn("Response:", response.data);
          this.res = response.data;
        })
        .catch((error) => {
          this.stylee =
            "color: black;border: 4px solid blue;border-radius: 4px;";
          this.generate = "Retry";
          this.res = "Something went wrong";
          console.warn("Not good man :(" + error);
        });

      console.warn(
        "called with http 7",
        this.option,
        this.minimum,
        this.maximum
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

