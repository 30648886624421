<template>
  <div class="subscriber__wrapper padding__top__botton margin__top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="subscriber__title__container">
            <h2 class="subscriber__title">Subscribe Newsletter</h2>
            <p class="subscriber__contnet">
              Enter your email address to register to our newsletter delivered
              on a regular basis!
            </p>
          </div>

          <form class="form-inline">
            <div class="form-group">
              <input type="text" v-model="email" placeholder="email" />
              <button type="submit" @click.stop.prevent="submit()" class="btn">
                subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="subscriber__con">
      <img :src="sidecolor" alt="sidecolor" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    submit() {
      // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] =
        "c0cd985e-1a9d-4cc5-b189-99b68e76d117";
      axios({
        url: "https://swalr7wf37.execute-api.eu-west-2.amazonaws.com/Prod/send",
        data: {
          toEmails: ["feedback.gurkul@gmail.com"],
          subject: "New User Subscribed",
          message: "User Subscribed: "+this.email,
        },
        method: "POST",

        responseType: "string",
      }).then((response) => {
        console.warn("Res5", response.data);
      });

      console.warn("called with http ");

      //if you want to send any data into server before redirection then you can do it here
      this.$router.push("/contact?email=" + this.email);
    },
  },
};
</script>

