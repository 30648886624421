import Home from '../pages/home/home'
import ContactPage from '../pages/ContactPage/ContactPage'
import Events from '../pages/Events/Events'
import TimeTable from '../pages/TimeTable/TimeTable'

import Error from '../pages/Error/Error'

export const routes=[
    {
        path:'/',
        component:Home,
    },
    
    {
        path:'/contact',
        name:'Contact',
        component:ContactPage,
    },
    
    {
        path:'/timetable',
        name:'timetable',
        component:TimeTable,
    },
    
    {
        path:'/events',
        name:'events',
        component:Events,
    },
    {
        path:'/error',
        name:'Error',
        component:Error,
    },
    

]